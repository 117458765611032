import { GroupSlideshow } from "./view/GroupSlideshow";
import { WindowWatcher } from "../umadash.js/util/WindowWatcher";

export class EntryIndex {

    private slideshows: GroupSlideshow[];
    private resizeHandler: () => void;

    constructor() {

        this.resizeHandler = this.resize.bind(this);

        this.slideshows = [];
        const $groupSlideshows: JQuery = $('.groupSlideshow');
        $groupSlideshows.each((index: number, element: HTMLElement) => {
            const $element = $(element);
            const s: GroupSlideshow = new GroupSlideshow($element);
            this.slideshows.push(s);
        });
    }


    public run(): void {
        for (let i: number = 0; i < this.slideshows.length; i += 1) {
            const s: GroupSlideshow = this.slideshows[i];
            s.start();
        }

        WindowWatcher.getInstance().addEventListener(WindowWatcher.Resize, () => {
            for (let i: number = 0; i < this.slideshows.length; i += 1) {
                const s: GroupSlideshow = this.slideshows[i];
                s.resize();
            }
        });
    }

    private resize(): void {

    }

}