import * as THREE from "three";
import { Scene, WebGLRenderer, WebGLRendererParameters, OrthographicCamera } from "three";
import { View } from "../../umadash.js/view/View";

export abstract class GLStage2D extends View<JQuery> {

    private updateHandler: () => void;
    private stopUpdate: boolean;

    protected renderer: WebGLRenderer;
    protected scene: Scene;
    protected camera: OrthographicCamera;
    protected width: number;
    protected height: number;
    protected sceneWidth: number;
    protected sceneHeight: number;
    protected request: any;

    protected abstract implUpdate(): void;
    protected abstract implResize(): void;

    constructor(rootElement: JQuery, parameters: WebGLRendererParameters = {
        antialias: false,
        alpha: true
    }) {
        super(rootElement);

        this._init(parameters)
    }

    private _init(parameters: WebGLRendererParameters): void {

        this.updateHandler = this.update.bind(this);
        this.stopUpdate = false;

        this.scene = new Scene();

        this.renderer = new WebGLRenderer(parameters);
        this.renderer.setClearColor(0x000000, 0);

        this.getView().append(this.renderer.domElement);

        this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
    }

    public resize(): void {

        this.stopUpdate = true;
        const width: number = this.getView().outerWidth();
        const height: number = this.getView().outerHeight();
        const ratio: number = height / width;

        this.width = width;
        this.height = height;

        if (width > height) {
            this.sceneWidth = 2.0;
            this.sceneHeight = 2.0 * ratio;
        }
        else {
            this.sceneWidth = 2.0 / ratio;
            this.sceneHeight = 2.0;
        }

        this.camera.left = -(this.sceneWidth / 2);
        this.camera.right = (this.sceneWidth / 2);
        this.camera.top = this.sceneHeight / 2;
        this.camera.bottom = -(this.sceneHeight / 2);
        this.camera.updateProjectionMatrix();

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(width, height);

        // ここを定義する
        this.implResize();

        this.stopUpdate = false;
    }


    private update(): void {
        this.request = requestAnimationFrame(this.updateHandler);
        if (this.stopUpdate) return;

        this.implUpdate();
    }


    protected startRendering(): void {
        this.stopRendering();
        this.update();
    }

    protected stopRendering(): void {
        if (this.request) {
            cancelAnimationFrame(this.request);
            this.request = null;
        }
    }


}