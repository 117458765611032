import * as THREE from "three";
import { Texture } from "three";
import { DoTween } from "../../../umadash.js/command/DoTween";
import { Easing } from "../../../umadash.js/tween/Easing";
import { Command } from "../../../umadash.js/command/Command";
const vertexShader = require("./vert.glsl");
const fragmentShader = require("./frag.glsl");

export default class SlideshowMesh extends THREE.Mesh {
  private src: string;
  private uniforms: any;

  constructor() {
    super();

    this.uniforms = {
      uTime: { type: "f", value: 0 },
      uResolution: { type: "fv", value: [0, 0] },
      uImageResolution: { type: "fv", value: [0, 0] },
      uTextureFrom: { type: "t", value: null },
      uTextureTo: { type: "t", value: null },
      uTransition: { type: "f", value: 0 }
    };

    this.material = new THREE.RawShaderMaterial({
      vertexShader: vertexShader.default,
      fragmentShader: fragmentShader.default,
      transparent: true,
      uniforms: this.uniforms
    });
  }

  public resize(
    width: number,
    height: number,
    sceneWidth: number,
    sceneHeight: number
  ) {
    this.uniforms.uResolution.value = [width, height];
    this.geometry = new THREE.PlaneBufferGeometry(
      sceneWidth,
      sceneHeight,
      1,
      1
    );
  }

  public update(time: number) {
    this.uniforms.uTime.value = time;
  }

  public updateImageResolution(width: number, height: number): void {
    this.uniforms.uImageResolution.value = [width, height];
  }

  public updateTextureFrom(texture: Texture): void {
    this.uniforms.uTextureFrom.value = texture;
  }

  public updateTextureTo(texture: Texture): void {
    this.uniforms.uTextureTo.value = texture;
  }

  public switchTexture() {
    this.uniforms.uTransition.value = 0;
    this.uniforms.uTextureFrom.value = this.uniforms.uTextureTo.value;
    this.uniforms.uTextureTo.value = null;
  }

  private transition: number;
  private tween: Command;
  public start(): void {
    if (this.tween) {
      this.tween.interrupt();
      this.tween = null;
    }

    // 0 から 1 へトランジション
    this.transition = 0;
    this.tween = new DoTween(
      this,
      { transition: 1.0 },
      null,
      2000,
      Easing.easeOutQuart,
      null,
      () => {
        this.uniforms.uTransition.value = this.transition;
      }
    );
    this.tween.execute();
  }
}
