import { Command } from '../command/Command';
import { EventDispatcher } from '../event/EventDispatcher';

export abstract class View<T = any> extends EventDispatcher {

    private static _id: number = 0;

    private view: T;

    private id: number;
    private showCommand: Command;
    private hideCommand: Command;

    private isShown: boolean;
    private isHidden: boolean;

    constructor(view: T = null) {
        super();

        this.view = view;
        this.id = View._id++;

        this.isShown = false;
        this.isHidden = true;
    }


    public show(): void {
        if (this.isShown) return;

        this.cancelAppearCommands();
        this.showCommand = this.getShowCommand(true);

        this.isShown = true;
        this.isHidden = false;
    }

    public hide(): void {
        if (this.isHidden) return;

        this.cancelAppearCommands();
        this.hideCommand = this.getHideCommand(true);

        this.isShown = false;
        this.isHidden = true;
    }

    private cancelAppearCommands(): void {
        if (this.showCommand) {
            this.showCommand.interrupt();
            this.showCommand = null;
        }

        if (this.hideCommand) {
            this.hideCommand.interrupt();
            this.hideCommand = null;
        }
    }

    public getView(): T {
        return this.view;
    }

    public getIsShown(): boolean {
        return this.isShown;
    }

    public getIsHidded(): boolean {
        return this.isHidden;
    }

    protected abstract getShowCommand(execute: boolean): Command;
    protected abstract getHideCommand(execute: boolean): Command;
    public abstract resize(): void;
}