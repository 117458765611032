import SlideshowMesh from "./SlideshowMesh/index";
import { GLStage2D } from "./GLStage2D";
import { Command } from "../../umadash.js/command/Command";
import { CommandUtil } from "../../umadash.js/util/CommandUtil";
import { Func } from "../../umadash.js/command/Func";
import { TextureLoader, Texture } from "three";
import Event from "../../umadash.js/event/Event";

export class Slideshow extends GLStage2D {
  public static Change: string = "change";

  private $elm: JQuery;
  private interval: number;
  private mesh: SlideshowMesh;

  private imgs: string[];
  private currentIndex: number;

  constructor($elm, interval: number = 6000) {
    super($elm);

    this.$elm = $elm;
    this.interval = interval;

    this.imgs = $elm.attr("data-imgs").split(",");
    this.currentIndex = 0;

    this.mesh = new SlideshowMesh();
    this.scene.add(this.mesh);
  }

  public start(): void {
    if (this.imgs.length < 1) return;
    // 一枚をロードする
    const firstImg: string = this.imgs[0];
    const texture = new TextureLoader().load(firstImg, (t: Texture) => {
      this.imageWidth = t.image.width;
      this.imageHeight = t.image.height;

      // 一枚目を表示する
      this.mesh.updateImageResolution(this.imageWidth, this.imageHeight);
      this.mesh.updateTextureTo(texture);
      this.mesh.start();

      // 次へ
      this.next();
    });
  }

  public stop(): void {
    this.stopTimer();
  }

  private timer: any;
  private loaded: boolean;
  private passed: boolean;
  private texture: THREE.Texture;

  private stopTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private next(): void {
    let nextIndex = this.currentIndex + 1;
    if (nextIndex >= this.imgs.length) {
      nextIndex = 0;
    }

    this.changeAt(nextIndex);

    // タイマーの開始
    this.passed = false;
    this.stopTimer();
    this.timer = setTimeout(() => {
      this.passed = true;
      if (this.passed && this.loaded) this.change();
    }, 5000);
  }

  public changeAt(index: number, skip: boolean = false): void {
    if (index < 0 || index >= this.imgs.length) return;

    // console.log('index', index);
    this.currentIndex = index;

    if (skip) {
      this.passed = true;
      this.stopTimer();
    }

    this.loaded = false;
    const url = this.imgs[index];
    this.texture = new TextureLoader().load(url, (t: Texture) => {
      this.imageWidth = t.image.width;
      this.imageHeight = t.image.height;

      this.loaded = true;
      if (this.passed && this.loaded) this.change();
    });
  }

  private imageWidth: number;
  private imageHeight: number;

  public getCurrentIndex(): number {
    return this.currentIndex;
  }

  private change(): void {
    this.dispatchEvent(new Event(Slideshow.Change));
    this.mesh.switchTexture();
    this.mesh.updateTextureTo(this.texture);
    this.mesh.updateImageResolution(this.imageWidth, this.imageHeight);
    this.mesh.start();

    this.next();
  }

  private loadImage(url: string, callback: () => void): void {
    const image: HTMLImageElement = new Image();
    image.onload = callback;
    image.src = url;
  }

  protected getShowCommand(execute: boolean): Command {
    return CommandUtil.serial(
      [
        new Func(() => {
          this.resize();
          this.startRendering();
          this.start();
        })
      ],
      execute
    );
  }

  protected getHideCommand(execute: boolean): Command {
    return CommandUtil.serial(
      [
        new Func(() => {
          this.stopRendering();
        })
      ],
      execute
    );
  }

  protected implResize() {
    if (this.mesh) {
      this.mesh.resize(
        this.width,
        this.height,
        this.sceneWidth,
        this.sceneHeight
      );
    }
  }

  protected implUpdate(): void {
    const time: number = performance.now() * 0.001;
    this.mesh.update(time);
    this.renderer.render(this.scene, this.camera);
  }
}
