import { EventDispatcher } from "../../umadash.js/event/EventDispatcher";
import Event from "../../umadash.js/event/Event";

export class PageControl extends EventDispatcher {

    public static Change: string = 'change';

    private $elm: JQuery;
    private $children: JQuery;
    private currentIndex: number;

    constructor($elm: JQuery) {
        super();

        this.$elm = $elm;

        this.init();
    }

    private init(): void {
        const $children: JQuery = this.$elm.children();
        $children.on('click', (e) => {
            const $target: JQuery = $(e.currentTarget);
            const index: number = parseInt($target.attr('data-index'), 10);
            this.setCurrentIndex(index);
            this.dispatchEvent(new Event(PageControl.Change));
        });
        this.$children = $children;
    }

    public setCurrentIndex(value: number): void {
        this.$children.eq(this.currentIndex).removeClass('pageControlItem--current');
        this.$children.eq(value).addClass('pageControlItem--current');
        this.currentIndex = value;
    }

    public getCurrentIndex(): number {
        return this.currentIndex;
    }

}