import { Slideshow } from "./Slideshow";
import { PageControl } from "./PageControl";

export class GroupSlideshow {

    private $elm: JQuery;

    private $btnCaption: JQuery;
    private $btnCaption__txt: JQuery;
    private $btnCaption__anchor: JQuery;

    private slideshow: Slideshow;
    private pageControl: PageControl;

    private titles: string[];
    private urls: string[];

    private changeSlideshowHandler: () => void;
    private changePageControlHandler: () => void;

    constructor($elm: JQuery) {
        this.$elm = $elm;

        this.$btnCaption = $elm.find('.btnCaption');
        this.$btnCaption__anchor = this.$btnCaption.find('.btnCaption__anchor');
        this.$btnCaption__txt = this.$btnCaption.find('.btnCaption__txt');

        this.titles = this.$btnCaption.attr('data-titles').split(',');
        this.urls = this.$btnCaption.attr('data-urls').split(',');

        this.init();
    }

    private init(): void {
        this.changeSlideshowHandler = this.onChangeSlideshow.bind(this);
        this.changePageControlHandler = this.onChangePageControl.bind(this);

        this.slideshow = new Slideshow(this.$elm.find('.groupSlideshow__body'), 5000);
        this.pageControl = new PageControl(this.$elm.find('.groupSlideshow__pageControl'));

        this.onChangeSlideshow();
    }

    private onChangeSlideshow(): void {
        const currentIndex: number = this.slideshow.getCurrentIndex();

        // ボタン更新
        const title = this.titles[currentIndex];
        const url = this.urls[currentIndex];

        this.$btnCaption__anchor.attr('href', url);
        this.$btnCaption__txt.text(title);

        // ページコントロール更新
        this.pageControl.setCurrentIndex(currentIndex);
    }

    private onChangePageControl(): void {
        const currentIndex: number = this.pageControl.getCurrentIndex();
        this.slideshow.changeAt(currentIndex, true);
    }

    public start(): void {
        this.slideshow.addEventListener(Slideshow.Change, this.changeSlideshowHandler);
        this.pageControl.addEventListener(PageControl.Change, this.changePageControlHandler);
        this.slideshow.show();
    }

    public stop(): void {
        this.slideshow.removeEventListener(Slideshow.Change, this.changeSlideshowHandler);
        this.pageControl.removeEventListener(PageControl.Change, this.changePageControlHandler);
        this.slideshow.stop();
    }

    public resize(): void {
        this.slideshow.resize();
    }



}